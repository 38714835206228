Date.prototype.timeDiff = function(date) {
	const now = this.getTime();
	const then = date.getTime();

	const timeDiff = then - now;

	const seconds = timeDiff / 1000;
	const minutes = seconds / 60;
	const hours = minutes / 60;
	const days = hours / 24;

	return {
		milliseconds: timeDiff > 0 ? Math.floor(timeDiff) : Math.ceil(timeDiff),
		seconds: seconds > 0 ? Math.floor(seconds) : Math.ceil(seconds),
		minutes: minutes > 0 ? Math.floor(minutes) : Math.ceil(minutes),
		hours: hours > 0 ? Math.floor(hours) : Math.ceil(hours),
		days: days > 0 ? Math.floor(days) : Math.ceil(days),
	};
};

Date.prototype.format = function(format) {
	const year = this.getFullYear();
	const month = this.getMonth();
	const day = this.getDate();
	const hours = this.getHours();
	const minutes = this.getMinutes();
	const seconds = this.getSeconds();

	return format
		.replace(/%y/g, year)
		.replace(/%m/g, String(month + 1).padStart(2, '0'))
		.replace(/%-m/g, String(month + 1))
		.replace(/%d/g, String(day).padStart(2, '0'))
		.replace(/%-d/g, String(day))
		.replace(/%H/g, String(hours).padStart(2, '0'))
		.replace(/%-H/g, String(hours))
		.replace(/%M/g, String(minutes).padStart(2, '0'))
		.replace(/%-M/g, String(minutes))
		.replace(/%S/g, String(seconds).padStart(2, '0'))
		.replace(/%-S/g, String(seconds));
};

export default () => {};
