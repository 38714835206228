<template>
	<div class="flex items-center justify-between bg-brown p-8 text-white">
		<div>{{ order.name }} ({{ order.email }})</div>
		<div class="flex items-center gap-2">
			<span class="relative z-10">
				<a
					v-if="!order.completed"
					href="#"
					class="grid h-8 w-8 place-items-center opacity-50 active:opacity-100"
					@click.prevent="toggleContextMenu"
				>
					<!-- icon-menu -->
					<svg
						width="24"
						height="6"
						viewBox="0 0 24 6"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g>
							<path
								d="M12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6Z"
								fill="white"
							/>
							<path
								d="M21 6C22.6569 6 24 4.65685 24 3C24 1.34315 22.6569 0 21 0C19.3431 0 18 1.34315 18 3C18 4.65685 19.3431 6 21 6Z"
								fill="white"
							/>
							<path
								d="M3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6Z"
								fill="white"
							/>
						</g>
					</svg>
				</a>
				<ContextMenu
					:active="contextMenu.active"
					:items="contextMenu.items"
					@close="$emit('toggle-context-menu', false)"
				/>
			</span>
			<a
				href="#"
				class="grid h-8 w-8 place-items-center opacity-50 active:opacity-100"
				@click.prevent="$emit('close')"
			>
				<!-- icon-close -->
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1 1L17 17M17 1L1 17"
						stroke="white"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</a>
		</div>
	</div>
</template>

<script>
import ContextMenu from '@/components/ContextMenu.vue';

export default {
	name: 'OrderHeader',
	components: {
		ContextMenu,
	},
	props: {
		order: Object,
		contextMenu: Object,
	},
	methods: {
		toggleContextMenu(e) {
			e.stopImmediatePropagation();
			this.$emit('toggle-context-menu', !this.contextMenu.active);
		},
	},
};
</script>
