<template>
	<div class="mb-4 flex items-center sm:mb-8">
		<TimeStepper
			v-model="hours"
			:step="HOURS_STEP"
		/>
		<div class="mb-8 flex flex-col items-center justify-center gap-2 sm:mb-0">
			<div class="h-1 w-1 rounded-full bg-black"></div>
			<div class="h-1 w-1 rounded-full bg-black"></div>
		</div>
		<TimeStepper
			v-model="minutes"
			:step="MINUTES_STEP"
		/>
	</div>
</template>

<script>
import TimeStepper from './TimeStepper.vue';

export default {
	name: 'Time',

	emits: ['update:modelValue', 'update:day'],

	components: {
		TimeStepper,
	},

	props: {
		modelValue: {
			type: Object,
			default: () => ({ hours: 0, minutes: 0 }),
		},
		day: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			HOURS_STEP: 1,
			MINUTES_STEP: 5,
		};
	},

	watch: {
		day(newDay, oldDay) {
			if (newDay === 'today' && oldDay === 'tomorrow') {
				this.resetTimeToNowIfNeeded();
			}
		},
	},

	computed: {
		hours: {
			get() {
				return this.modelValue.hours;
			},
			set(value) {
				let roundedHours = this.roundToStep(value, this.HOURS_STEP);

				// Handle hours overflow and underflow
				if (roundedHours >= 24) {
					roundedHours = 0;

					// Update day to 'tomorrow' if hours go beyond 23
					this.$emit('update:day', 'tomorrow');

					// Use $nextTick to ensure day is updated before any further checks
					this.$nextTick(() => {
						this.$emit('update:modelValue', { ...this.modelValue, hours: roundedHours });
					});

					return; // Exit to avoid running further checks with incorrect day value
				} else if (roundedHours < 0) {
					roundedHours = 23;

					if (this.day === 'tomorrow') {
						// Update day to 'today' if hours go below 0
						this.$emit('update:day', 'today');

						// Use $nextTick to ensure day is updated before any further checks
						this.$nextTick(() => {
							this.$emit('update:modelValue', { ...this.modelValue, hours: roundedHours });
						});

						return; // Exit to avoid running further checks with incorrect day value
					}
				}

				// Prevent going backwards in time if it is today
				if (this.isToday) {
					const currentHours = new Date().getHours();
					if (roundedHours < currentHours) {
						roundedHours = currentHours;
					}
				}

				// Emit the updated hour value
				this.$emit('update:modelValue', { ...this.modelValue, hours: roundedHours });
			},
		},
		minutes: {
			get() {
				return this.modelValue.minutes;
			},
			set(value) {
				let roundedMinutes = this.roundToStep(value, this.MINUTES_STEP);
				let roundedHours = this.hours;

				// Handle minute overflow and underflow
				if (roundedMinutes >= 60) {
					roundedMinutes = 0;
					roundedHours += 1;

					if (roundedHours >= 24) {
						roundedHours = 0;
						this.$emit('update:day', 'tomorrow'); // Update day to 'tomorrow' if hours go beyond 23
					}
				} else if (roundedMinutes < 0) {
					roundedMinutes = 59;
					roundedHours -= 1;

					if (roundedHours < 0) {
						roundedHours = 23;
						if (this.day === 'tomorrow') {
							this.$emit('update:day', 'today'); // Update day to 'today' if hours go below 0
						}
					}
				}

				// Prevent going backwards in time if it is today
				if (this.isToday) {
					const currentMinutes = new Date().getMinutes();
					if (roundedMinutes < currentMinutes && roundedHours === new Date().getHours()) {
						roundedMinutes = currentMinutes;
					}
				}

				this.$emit('update:modelValue', { ...this.modelValue, hours: roundedHours, minutes: roundedMinutes });
			},
		},

		isToday() {
			return this.day === 'today';
		},
	},

	methods: {
		roundToStep(value, step) {
			// Rounds the value to the nearest multiple of the step
			return Math.round(value / step) * step;
		},

		resetTimeToNowIfNeeded() {
			const now = new Date();
			const currentHours = now.getHours();
			const currentMinutes = now.getMinutes();

			// Check if the current time is in the past
			if (this.hours < currentHours || (this.hours === currentHours && this.minutes < currentMinutes)) {
				this.$emit('update:modelValue', { hours: currentHours, minutes: currentMinutes });
			}
		},
	},
};
</script>
