<template>
	<div class="relative flex flex-col items-center py-10 sm:py-4 pb-16 sm:pb-10 bg-light-blue">
		<div class="w-full max-w-screen-lg mx-auto px-8">
			<div class="flex flex-col gap-4 sm:flex-row items-center justify-between w-full">
				<div class="text-lg">Bíleggingar</div>

				<div class="flex rounded-full bg-white overflow-hidden">
					<a href="#" class="py-5 px-10 sm:px-16" :class="activeClass(filter === null)" @click.prevent="filter = null">Nýggjar</a>

					<a href="#" class="py-5 px-10 sm:px-16" :class="activeClass(filter === 'done')" @click.prevent="filter = 'done'">Lidnar</a>
				</div>
			</div>
		</div>

		<div class="separator absolute bottom-0 w-full h-5"></div>
	</div>
</template>

<script>
export default {
	name: 'Header',

	data() {
		return {
			filter: null,
		};
	},

	watch: {
		filter: function (value) {
			this.$emit('filter-change', value);
		},
	},

	methods: {
		activeClass(condition) {
			if (condition) {
				return 'text-white bg-green active:bg-green-shade';
			}

			return 'active:bg-white-shade';
		},
	},
};
</script>

<style scoped>
.separator {
	background: url('../assets/separator.svg');
}
</style>
