import axios from 'axios';

export default class OrderService {
	static async getOrders() {
		return axios.get(`${process.env.VUE_APP_FOODORDER_MIDDLEWARE}/orders`).then(
			(response) => {
				return response.data;
			},
			(error) => {
				console.error('Error fetching orders', error);
			}
		);
	}

	static async getStationOrders(stationUuid) {
		return axios.get(`${process.env.VUE_APP_FOODORDER_MIDDLEWARE}/stations/${stationUuid}/orders`).then(
			(response) => {
				return response.data;
			},
			(error) => {
				console.error('Error fetching orders', error);
			}
		);
	}

	static async getStationCompletedOrders(stationUuid, take = 12, skip = 0) {
		return axios.get(`${process.env.VUE_APP_FOODORDER_MIDDLEWARE}/stations/${stationUuid}/orders/completed?take=${take}&skip=${skip}`).then(
			(response) => {
				return response.data;
			},
			(error) => {
				console.error('Error fetching orders', error);
			}
		);
	}

	static async acceptOrder(order, expectedCompletionTimeAt) {
		return axios
			.patch(`${process.env.VUE_APP_FOODORDER_MIDDLEWARE}/orders/${order.uuid}/toggle-accepted`, {
				expectedCompletionTimeAt: expectedCompletionTimeAt,
			})
			.then(
				(response) => {
					return response.data;
				},
				(error) => {
					console.error('Error accepting order', error);
				}
			);
	}

	static async completeOrder(order) {
		return axios.patch(`${process.env.VUE_APP_FOODORDER_MIDDLEWARE}/orders/${order.uuid}/toggle-completed`).then(
			(response) => {
				return response.data;
			},
			(error) => {
				console.error('Error completing order', error);
			}
		);
	}

	static async setExpectedCompletionTime(order, expectedCompletionTimeAt) {
		return await axios
			.patch(`${process.env.VUE_APP_FOODORDER_MIDDLEWARE}/orders/${order.uuid}/set-expected-completion-time`, {
				expectedCompletionTimeAt: expectedCompletionTimeAt,
			})
			.then(
				(response) => {
					return response.data;
				},
				(error) => {
					console.error('Error setting completion time', error);
				}
			);
	}

	static async deleteOrder(order) {
		return axios.delete(`${process.env.VUE_APP_FOODORDER_MIDDLEWARE}/orders/${order.uuid}`).then(
			(response) => {
				return response.data;
			},
			(error) => {
				console.error('Error deleting order', error);
			}
		);
	}
}
