<template>
	<div class="flex justify-center items-center space-x-4 my-8">
		<a @click.prevent="previous" href="#" class="flex-shrink-0 grid place-items-center w-10 h-10 bg-green text-white text-xl font-bold rounded-full hover:bg-green-shade" :class="{ 'opacity-50': !canGoBack }">&lt;</a>

		<span>
			<span>{{ first }} <span class="text-black/30"> - </span> {{ last }}</span>

			<span class="text-black/30"> av </span>

			<span>{{ entriesTotal }}</span>
		</span>

		<a @click.prevent="next" href="#" class="flex-shrink-0 grid place-items-center w-10 h-10 bg-green text-white text-xl font-bold rounded-full hover:bg-green-shade" :class="{ 'opacity-50': !canGoForward }">&gt;</a>
	</div>
</template>

<script>
export default {
	name: 'Pagination',

	props: {
		page: Number,
		pageSize: Number,
		entriesTotal: Number,
	},

	emits: ['update:page'],

	methods: {
		previous() {
			if (!this.canGoBack) {
				return;
			}

			this.$emit('update:page', this.page - 1);
		},

		next() {
			if (!this.canGoForward) {
				return;
			}

			this.$emit('update:page', this.page + 1);
		},
	},

	computed: {
		first: function () {
			return this.pageSize * (this.page - 1) + 1;
		},

		last: function () {
			const last = this.pageSize * (this.page - 1) + this.pageSize;

			return last < this.entriesTotal ? last : this.entriesTotal;
		},

		canGoBack: function () {
			return this.page > 1;
		},

		canGoForward: function () {
			const pages = Math.ceil(this.entriesTotal / this.pageSize);

			return this.page < pages;
		},
	},
};
</script>
