<template>
	<div class="pt-4">
		<div class="rounded-md bg-white-shade p-2">
			{{ comment }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		comment: {
			type: String,
		},
	},
};
</script>
