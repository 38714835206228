<template>
	<div
		@click="active = !active"
		class="v-select relative flex items-center justify-between rounded bg-white py-3 px-5"
	>
		<div>{{ modelValue || label || '&nbsp;' }}</div>

		<!-- icon-chevron -->
		<svg
			width="18"
			height="8"
			viewBox="0 0 18 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			class="transition"
			:class="{ 'rotate-180': !active }"
		>
			<path
				d="M17 6.81818L9 1L0.999999 6.81818"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>

		<div
			class="absolute top-full left-0 z-20 mt-1 max-h-64 w-full overflow-y-auto rounded bg-white py-2 shadow transition"
			:class="{ 'pointer-events-none -translate-y-8 opacity-0': !active }"
		>
			<a
				v-for="(option, index) in options"
				:key="index"
				href="#"
				@click.prevent="value = option"
				class="block px-5 py-2"
				:class="{ 'bg-grey/10': option == modelValue }"
			>
				{{ option }}
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VSelect',

	props: {
		modelValue: String,
		options: {
			type: Array,
			required: true,
		},
		label: {
			type: String,
		},
	},

	emits: ['update:modelValue'],

	data() {
		return {
			active: false,
			value: '',
		};
	},

	watch: {
		value: function (value) {
			this.$emit('update:modelValue', value);
		},

		modelValue: function (modelValue) {
			console.log('modelValue', modelValue);
		},
	},

	mounted() {
		window.addEventListener('click', (e) => {
			if (!e.target.closest('.v-select')) {
				this.active = false;
			}
		});
	},
};
</script>
