<template>
	<div class="flex flex-col-reverse sm:flex-row">
		<template v-if="isUpdatingTime">
			<a
				href="#"
				class="w-full bg-brown p-6 text-center font-medium text-white active:bg-brown-shade sm:p-8"
				@click.prevent="$emit('toggle-update-time')"
			>
				Angra
			</a>

			<a
				href="#"
				class="col-start-1 w-full bg-green p-6 text-center font-medium text-white active:bg-green-shade sm:p-8"
				@click.prevent="$emit('update-time')"
			>
				Rætta tíð
			</a>
		</template>

		<template v-else>
			<a
				href="#"
				id="close-button"
				class="w-full bg-brown p-6 text-center font-medium text-white active:bg-brown-shade sm:p-8"
				@click.prevent="$emit('close')"
			>
				Lat aftur
			</a>

			<a
				href="#"
				v-if="order && !order.acceptedAt"
				class="col-start-1 w-full bg-green p-6 text-center font-medium text-white active:bg-green-shade sm:p-8"
				@click.prevent="$emit('accept-order')"
			>
				Vátta bílegging
			</a>

			<a
				href="#"
				v-if="order && order.acceptedAt && !order.completedAt"
				class="col-start-1 w-full bg-green p-6 text-center font-medium text-white active:bg-green-shade sm:p-8"
				@click.prevent="$emit('complete-order')"
			>
				Klárt at heinta
			</a>
		</template>
	</div>
</template>

<script>
export default {
	name: 'OrderFooter',
	props: {
		isUpdatingTime: Boolean,
		order: Object,
	},
};
</script>
