<template>
	<div
		id="background"
		class="fixed top-0 left-0 h-full max-h-full w-full bg-dark-blue bg-opacity-90 transition-opacity"
		:class="{ 'pointer-events-none opacity-0': !visible }"
		@click="close"
	>
		<div class="absolute top-1/2 left-1/2 mx-auto w-full max-w-xl -translate-y-1/2 -translate-x-1/2 transform">
			<div v-if="order">
				<!-- Order Modal Header -->
				<OrderModalHeader
					:order="order"
					@close="$emit('close')"
					@toggle-context-menu="toggleContextMenu"
					v-model:context-menu="contextMenu"
				/>

				<!-- Order Type Indicator -->
				<OrderModalTypeIndicator :dine-in="order.dineIn" />

				<!-- Order Items -->
				<div
					class="flex max-h-108 flex-col items-center justify-between overflow-auto bg-light-brown p-8 pb-0 text-grey sm:flex-row-reverse"
					style="min-height: 300px"
				>
					<div class="flex flex-col items-center gap-8">
						<div
							class="flex w-full justify-evenly gap-4"
							v-if="isUpdatingTime || !order.acceptedAt"
						>
							<button
								:class="['rounded-full px-4 py-2', day === 'today' ? 'bg-green text-white' : 'bg-gray-200 text-black']"
								@click="setDate('today')"
							>
								Í dag
							</button>
							<button
								:class="['rounded-full px-4 py-2', day === 'tomorrow' ? 'bg-green text-white' : 'bg-gray-200 text-black']"
								@click="setDate('tomorrow')"
							>
								Í morgin
							</button>
						</div>

						<Time
							v-if="isUpdatingTime || !order.acceptedAt"
							v-model="time"
							:day="day"
							@update:day="day = $event"
						/>
					</div>

					<OrderModalItem
						v-for="item in items"
						:key="item.uuid"
						:item="item"
						:get-ingredient-differences="getIngredientDifferences"
					/>
				</div>

				<div
					v-if="order.comment"
					class="bg-light-brown px-8 pb-8"
				>
					<OrderCommentSection :comment="order.comment" />
				</div>

				<!-- Order Modal Footer -->
				<OrderModalFooter
					:is-updating-time="isUpdatingTime"
					:order="order"
					@close="close"
					@accept-order="acceptOrder"
					@complete-order="completeOrder"
					@update-time="updateTime"
					@toggle-update-time="() => (isUpdatingTime = false)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import OrderCommentSection from '@/components/OrderModal/OrderCommentSection.vue';
import OrderModalFooter from '@/components/OrderModal/OrderModalFooter.vue';
import OrderModalHeader from '@/components/OrderModal/OrderModalHeader.vue';
import OrderModalItem from '@/components/OrderModal/OrderModalItem.vue';
import OrderModalTypeIndicator from '@/components/OrderModal/OrderModalTypeIndicator.vue';
import Time from '@/components/Time/Time.vue';
import OrderService from '@/services/OrderService';

export default {
	name: 'Order',

	emits: ['order-accepted', 'order-completed', 'order-updated', 'order-deleted', 'close'],

	components: {
		Time,
		OrderModalFooter,
		OrderModalHeader,
		OrderModalItem,
		OrderModalTypeIndicator,
		OrderCommentSection,
	},

	props: {
		order: {
			type: Object,
		},

		visible: {
			type: Boolean,
		},
	},

	data() {
		return {
			time: { hours: 0, minutes: 0 },
			day: '',
			datetime: null,

			isUpdatingTime: false,

			contextMenu: {
				active: false,
				items: [],
			},
		};
	},

	mounted() {
		window.addEventListener('keydown', (e) => {
			if (e.key !== 'Escape') {
				return;
			}

			this.$emit('close');
		});
	},

	computed: {
		items() {
			if (!this.order) {
				return [];
			}

			if (!this.order?.data?.items?.length) {
				return [];
			}

			return this.order.data.items;
		},
	},

	watch: {
		order: {
			deep: true,
			handler: function (value) {
				this.isUpdatingTime = false;

				this.contextMenu.items = [
					{
						title: 'Rætta tíð',
						active: value && value.acceptedAt && !value.completedAt,
						callback: async () => {
							this.isUpdatingTime = true;
						},
					},
					{
						title: 'Angra góðkenning',
						active: value && value.acceptedAt && !value.completedAt,
						callback: async () => {
							await OrderService.acceptOrder(this.order, null)
								.then((order) => {
									this.$emit('order-updated', order);
								})
								.finally(() => {
									this.$emit('close');
								});
						},
					},
					{
						title: 'Strika ordra',
						active: value && !value.completedAt,
						color: 'red',
						callback: async () => {
							if (!window.confirm('Ert tú vís/ur í, at tú ynskir at strika ordran?')) {
								return;
							}

							await OrderService.deleteOrder(value)
								.then(() => {
									this.$emit('order-deleted', value);
								})
								.finally(() => {
									this.$emit('close');
								});
						},
					},
				];

				// Watcher to update datetime if order.expectedCompletionTimeAt changes
				if (value?.expectedCompletionTimeAt) {
					this.setDateAndTimeFromDatetime(new Date(value.expectedCompletionTimeAt));
				} else {
					this.setDateAndTimeFromDatetime(new Date());
				}
			},
		},
	},

	methods: {
		acceptOrder() {
			const datetime = this.getCombinedDatetime();
			this.$emit('order-accepted', datetime);
		},

		completeOrder() {
			this.$emit('order-completed');
		},

		getIngredientDifferences(item) {
			const { added, removed } = item.ingredientDifference;

			if (!added.length && !removed.length) {
				return [];
			}

			return [...added.map((item) => `+ ${item.name}`), ...removed.map((item) => `- ${item.name}`)];
		},

		toggleContextMenu(active) {
			this.contextMenu.active = active;
		},
		close(e) {
			const target = e.target;

			if (target.id === 'background' || target.id === 'close-button') {
				this.$emit('close');
			}
		},

		async updateTime() {
			const order = await OrderService.setExpectedCompletionTime(this.order, this.getCombinedDatetime());
			this.$emit('order-updated', order);
			this.$emit('close');
		},

		setDate(day) {
			this.day = day;

			// Re-assign `time` to ensure Vue reactivity
			this.time = {
				hours: this.time.hours,
				minutes: this.time.minutes,
			};
		},

		getCombinedDatetime() {
			let baseDate;

			// Determine the base day based on the value of `day`
			if (this.day === 'today') {
				baseDate = new Date(new Date().setHours(0, 0, 0, 0));
			} else if (this.day === 'tomorrow') {
				const tomorrow = new Date();
				tomorrow.setDate(tomorrow.getDate() + 1);
				baseDate = new Date(tomorrow.setHours(0, 0, 0, 0));
			} else {
				baseDate = new Date(new Date().setHours(0, 0, 0, 0));
			}

			// Set the hours and minutes from `time`
			baseDate.setHours(this.time.hours);
			baseDate.setMinutes(this.time.minutes);

			// Return the combined datetime
			return baseDate;
		},

		setDateAndTimeFromDatetime(datetime) {
			const currentDate = new Date();
			const parsedDate = new Date(datetime);

			if (parsedDate.toDateString() === currentDate.toDateString()) {
				this.day = 'today';
			} else if (parsedDate.toDateString() === new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1).toDateString()) {
				this.day = 'tomorrow';
			} else {
				this.day = 'today';
			}

			// Update `time` based on the parsed datetime, and ensure reactivity by replacing the object
			this.time = {
				hours: parsedDate.getHours(),
				minutes: parsedDate.getMinutes(),
			};
		},
	},
};
</script>
