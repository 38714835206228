<template>
	<div
		class="bg-dark-blue py-3 text-center text-lg text-white"
		v-if="!dineIn"
	>
		Útvið
	</div>
	<div
		class="bg-green py-3 text-center text-lg text-white"
		v-else
	>
		Inni
	</div>
</template>

<script>
export default {
	name: 'OrderTypeIndicator',
	props: {
		dineIn: Boolean,
	},
};
</script>
