<template>
	<div class="absolute top-full -mt-1 right-0 w-48 rounded-xl text-black bg-white transform transition overflow-hidden" :class="activeClass">
		<a
			href="#"
			v-for="(item, index) in items"
			:key="index"
			@click.prevent="handleCallback(item)"
			class="block px-5 py-5 transition-colors border-b last:border-b-0 border-light-blue"
			:class="`${item.color === 'red' ? 'text-red active:text-white active:bg-red' : 'active:bg-white-shade'} ${!item.active && 'opacity-25'}`"
			>{{ item.title }}</a
		>
	</div>
</template>

<script>
export default {
	name: 'ContextMenu',

	props: {
		items: {
			type: Object,
			required: true,
		},

		active: {
			type: Boolean,
			required: true,
		},
	},

	mounted() {
		window.addEventListener('click', () => {
			this.$emit('close');
		});
	},

	computed: {
		activeClass() {
			return this.active ? 'opacity-100 translate-y-0 pointer-events-auto' : 'opacity-0 -translate-y-2 pointer-events-none';
		},
	},

	methods: {
		handleCallback(item) {
			if (!item.active) {
				return false;
			}

			item.callback();
		},
	},
};
</script>
