<template>
	<main class="h-full">
		<Header @filter-change="setFilter" />

		<div
			v-if="loading"
			class="my-12 flex justify-center"
		>
			<VLoader class="h-8 w-8" />
		</div>

		<Transition>
			<div v-if="!loading">
				<div
					v-if="stations.length"
					class="mx-auto max-w-md px-4 py-4"
				>
					<VSelect
						v-model="stationName"
						label="Vel støð"
						:options="stationNames"
					/>
				</div>

				<div
					v-if="stationLoading"
					class="my-12 flex justify-center"
				>
					<VLoader class="h-8 w-8" />
				</div>

				<div v-if="!stationLoading">
					<div
						v-if="filter === 'done'"
						class="mx-auto w-full max-w-md py-6"
					>
						<div v-if="completedOrders?.length">
							<div class="mb-5 font-bold">Lidnar bíleggingar ({{ completedOrders.length }})</div>

							<div class="flex flex-col gap-3">
								<Order
									v-for="(order, index) in completedOrders"
									:key="index"
									:order="order"
									@click="showPopup"
								/>
							</div>
						</div>

						<NoOrder v-if="!completedOrders?.length && !acceptedOrders?.length" />

						<Pagination
							v-model:page="page"
							:page-size="pageSize"
							:entries-total="entriesTotal"
						/>
					</div>

					<div
						v-if="filter !== 'done'"
						class="mx-auto max-w-screen-lg py-6"
					>
						<div
							v-if="pendingOrders?.length || acceptedOrders?.length"
							class="grid max-w-screen-lg gap-8 px-8 md:grid-cols-2"
						>
							<div>
								<div class="mb-5 font-bold">Nýggjar bíleggingar ({{ pendingOrders.length }})</div>

								<div class="flex flex-col gap-3 overflow-auto md:max-h-108">
									<Order
										v-for="(order, index) in sort(pendingOrders, 'createdAt', 'DESC')"
										:key="index"
										:order="order"
										@click="
											popup.order = $event;
											popup.visible = true;
										"
									/>
								</div>
							</div>

							<div>
								<div class="mb-5 font-bold">Í gongd ({{ acceptedOrders.length }})</div>

								<div class="flex flex-col gap-3 overflow-auto md:max-h-108">
									<Order
										v-for="(order, index) in acceptedOrders"
										:key="index"
										:order="order"
										@click="showPopup"
									/>
								</div>
							</div>
						</div>

						<NoOrder v-if="!pendingOrders?.length" />
					</div>
				</div>
			</div>
		</Transition>

		<!-- The Order Popup -->
		<OrderModal
			:visible="popup.visible"
			:order="popup.order"
			@close="closePopup"
			@order-accepted="acceptOrder"
			@order-completed="completeOrder"
			@order-updated="updateOrder"
			@order-deleted="deleteOrder"
		/>
	</main>
</template>

<script>
import OrderService from '@/services/OrderService';
import axios from 'axios';

import Header from '@/components/Header.vue';
import NoOrder from '@/components/NoOrder.vue';
import Order from '@/components/Order.vue';
import OrderModal from '@/components/OrderModal/OrderModal.vue';
import Pagination from '@/components/Pagination.vue';
import VLoader from '@/components/VLoader.vue';
import VSelect from '@/components/VSelect.vue';

import io from 'socket.io-client';

export default {
	name: 'App',

	components: {
		Header,
		Order,
		NoOrder,
		OrderModal,
		VSelect,
		VLoader,
		Pagination,
	},

	data() {
		return {
			popup: {
				visible: false,
				order: null,
				socket: null,
			},
			audio: null,
			page: 1,
			pageSize: 12,
			entriesTotal: 0,
			interval: null,
			loading: true,
			stationLoading: true,
			filter: null,
			orders: [],
			completedOrders: [],
			stations: [],
			stationName: '',
		};
	},

	async mounted() {
		/**
		 * Fetch all Stations.
		 */
		await axios.get(`${process.env.VUE_APP_FOODORDER_MIDDLEWARE}/stations`).then(
			(response) => {
				this.stations = response.data;
			},
			(error) => {
				console.error('Error fetching orders', error);
			}
		);

		let station = localStorage.getItem('station');

		if (station) {
			station = JSON.parse(station);

			this.stationName = station.name;
		}

		this.loading = false;

		this.unlockAudio();

		document.body.addEventListener('click', this.unlockAudio);
		document.body.addEventListener('touchstart', this.unlockAudio);
	},

	unmounted() {
		clearInterval(this.interval);
	},

	computed: {
		pendingOrders() {
			if (!this.orders) {
				return [];
			}

			return this.orders.filter((order) => !order.acceptedAt && !order.completedAt);
		},

		acceptedOrders() {
			if (!this.orders) {
				return [];
			}

			let acceptedOrders = this.orders.filter((order) => order.acceptedAt && !order.completedAt);

			acceptedOrders.sort((a, b) => {
				const aFinishTime = new Date(a.expectedCompletionTimeAt).getTime();
				const bFinishTime = new Date(b.expectedCompletionTimeAt).getTime();

				return aFinishTime < bFinishTime ? -1 : 1;
			});

			return acceptedOrders;
		},

		stationNames() {
			if (!this.stations) {
				return [];
			}

			return this.stations.map((station) => station.name);
		},

		stationUuid() {
			if (!this.stations?.length) {
				return '';
			}

			const stationUuid = this.stations.find((station) => station.name == this.stationName)?.uuid;

			return stationUuid;
		},
	},

	watch: {
		stationUuid: function () {
			this.init();
		},

		filter: async function (filter) {
			if (filter != 'done') {
				return;
			}

			this.stationLoading = true;

			const response = await OrderService.getStationCompletedOrders(this.stationUuid);

			this.completedOrders = response[0];

			this.stationLoading = false;
		},

		page: async function (page) {
			this.stationLoading = true;

			const response = await OrderService.getStationCompletedOrders(this.stationUuid, this.pageSize, this.pageSize * (page - 1));

			this.completedOrders = response[0];

			this.stationLoading = false;
		},
	},

	methods: {
		async init() {
			if (!this.stationUuid) {
				return;
			}

			localStorage.setItem(
				'station',
				JSON.stringify({
					name: this.stationName,
					uuid: this.stationUuid,
				})
			);

			/**
			 * Get all orders for selected station.
			 */
			this.stationLoading = true;

			this.orders = await OrderService.getStationOrders(this.stationUuid);
			const response = await OrderService.getStationCompletedOrders(this.stationUuid);
			this.completedOrders = response[0];
			this.entriesTotal = response[1];

			this.stationLoading = false;

			/**
			 * Map the Orders.
			 */
			this.orders = this.orders.map((order) => {
				if (order.expectedCompletionTimeAt) {
					const expectedCompletionTime = new Date(order.expectedCompletionTimeAt);

					return {
						expectedTime: expectedCompletionTime,
						...order,
					};
				}

				return order;
			});

			/**
			 * Create socket.io instance.
			 */
			this.socket?.close?.();

			this.socket = io(`${process.env.VUE_APP_FOODORDER_MIDDLEWARE_SOCKET_URL}/orders`, {
				transports: ['websocket', 'polling'],
				upgrade: true,
			});

			/**
			 * Data example:
			 * {
			 * type: 'CreateOrder',
			 * order: {},
			 * }
			 */

			// Add order when new order is published
			this.socket.on(this.stationUuid, (data) => {
				if (data.type == 'OrderCreated') {
					if (this.audio) {
						this.audio.play();
					}

					this.orders.push(data.order);
				}

				if (data.type == 'ToggleAccepted') {
					const order = this.orders.find((order) => order.uuid == data.orderId);

					if (order.acceptedAt) {
						order.acceptedAt = null;
					} else {
						order.acceptedAt = new Date();
					}
				}

				if (data.type == 'SetExpectedCompletionTimeAt') {
					const order = this.orders.find((order) => order.uuid == data.orderId);

					order.expectedCompletionTimeAt = data.expectedCompletionTimeAt;
				}

				if (data.type == 'ToggleCompleted') {
					const order = this.orders.find((order) => order.uuid == data.orderId);

					if (order.completedAt) {
						order.completedAt = null;
					} else {
						order.completedAt = new Date();
					}
				}

				if (data.type == 'RemoveOrder') {
					this.orders = this.orders.filter((order) => order.uuid != data.orderId);
				}
			});

			// Play a sound every 15 seconds if the station has pending orders.
			this.interval = setInterval(() => {
				if (!this.pendingOrders.filter((order) => !order.wantedAt).length) {
					return;
				}

				if (!this.audio) {
					return;
				}

				this.audio.play();
			}, 1000 * 15);
		},

		unlockAudio() {
			this.audio = new Audio('/notification.mp3');

			this.audio.play();
			this.audio.pause();
			this.audio.currentTime = 0;

			document.body.removeEventListener('click', this.unlockAudio);
			document.body.removeEventListener('touchstart', this.unlockAudio);
		},

		sort(orders, field, order = 'ASC') {
			return orders.sort((a, b) => {
				if (!a[field] || !b[field]) {
					console.error(`Field, ${field}, unknown when sorting array`);

					return 0;
				}

				if (order === 'DESC') {
					return a[field] < b[field] ? -1 : 1;
				}

				return a[field] > b[field] ? -1 : 1;
			});
		},

		showPopup(order) {
			this.popup.order = order;
			this.popup.visible = true;
		},

		closePopup() {
			this.popup.visible = false;

			// remove popup order after a while, so as to not see any clipping
			setTimeout(() => {
				this.popup.order = null;
			}, 250);
		},

		updateOrder(order) {
			const index = this.orders.findIndex((item) => item.id == order.id);

			this.orders.splice(index, 1, order);
		},

		deleteOrder(order) {
			this.orders = this.orders.filter((item) => item.id !== order.id);
		},

		acceptOrder(expectedCompletionTimeAt) {
			OrderService.acceptOrder(this.popup.order, expectedCompletionTimeAt)
				.then((order) => {
					this.updateOrder(order);

					this.closePopup();
				})
				.finally(() => {
					this.popup.order = null;
					this.popup.visible = false;
				});
		},

		completeOrder() {
			OrderService.completeOrder(this.popup.order)
				.then((order) => {
					this.updateOrder(order);

					this.closePopup();
				})
				.finally(() => {
					this.popup.order = null;
					this.popup.visible = false;
				});
		},

		setFilter(filter) {
			this.filter = filter;
		},
	},
};
</script>

<style>
.v-enter-active {
	transition: 0.25s all ease-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
	transform: translateY(12px);
}
</style>
